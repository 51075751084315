import { Box } from '@mui/material';
import React from 'react';
import styles from '../../common/styling/styles';
import { SubText } from '../../common/texts';

const Author = ({ author }) => {
  return (
    <Box sx={{ pb: 2 }}>
      <SubText
        variant="subtitle2"
        fontColor="rgb(145, 158, 171)"
        marginY={styles.allOne}
        fontWeight={600}
        value="AUTHOR:"
        textAlignment={styles.alignStyleLLLL}
      />
      <SubText
        variant="body2"
        fontWeight={700}
        fontColor={styles.blogBlue}
        marginY={styles.allZero}
        textAlignment={styles.alignStyleLLLL}
        value={author}
      />
    </Box>
  );
};

export default Author;
