import { Box } from "@mui/material";
import LazyLoad from "react-lazyload";
import React from "react";

const BlogMainPicture = ({ imageUrl, altText }) => {
  return (
    <LazyLoad height={400}>
      <Box
        aria-label={altText}
        sx={{
          position: "relative",
          display: "flex",
          borderBottomLeftRadius: "16px",
          borderBottomRightRadius: "16px",
          backgroundImage: `url(${imageUrl})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          minHeight: 400,
          pb: 5,
        }}
      ></Box>
    </LazyLoad>
  );
};

export default BlogMainPicture;
