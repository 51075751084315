import { Box } from "@mui/material";
import React from "react";
import Body from "../../common/body/Body";
import styles from "../../common/styling/styles";
import { MainText } from "../../common/texts";

const BlogDetailsPageHead = ({ data }) => {
  return (
    <Body paddingY={styles.allFive} backgroundColor={styles.lightGrey}>
      <Box
        sx={{
          width: { xs: "100%", md: "80%" },
          pt: 10,
        }}
      >
        <MainText
          textVariant="h1"
          fontWeight={500}
          textAlignment={styles.alignStyleLLLL}
          fontColor={styles.deepGreen}
          value={data}
        />
      </Box>
    </Body>
  );
};

export default BlogDetailsPageHead;
