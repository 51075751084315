import { graphql } from 'gatsby';
import React from 'react';

import StandardSEO from '../components/common/seo/StandardSEO';
import BlogDetails from '../components/PageComps/BlogDetailsPageComps/BlogDetails';

export const query = graphql`
  query BlogPostTemplateQuery($id: String!, $language: String!) {
    post: sanityPost(id: { eq: $id }) {
      id
      seo {
        title
        description
        keywords
        name
        type
      }
      title
      slug {
        current
      }
      publishedAt
      author
      excerpt
      mainImage {
        asset {
          url
        }
        altText
      }
      category
      paragraphs {
        title
        body: _rawBody
        image {
          asset {
            altText
            url
          }
        }
      }
    }
    sanityBlogPage(language: { eq: $language }) {
      blogsPreview {
        title
        buttonName
      }
    }
    allSanityPost(
      sort: { publishedAt: DESC }
      filter: { language: { eq: $language } }
    ) {
      nodes {
        title
        slug {
          current
        }
        publishedAt
        author
        excerpt
        mainImage {
          asset {
            gatsbyImageData(placeholder: BLURRED, width: 600, formats: [WEBP])
          }
          altText
        }
        category
        paragraphs {
          title
          body: _rawBody
          image {
            asset {
              altText
              url
            }
          }
        }
      }
    }
  }
`;

const Blog = ({ data }) => {
  return <BlogDetails data={data} />;
};

export default Blog;

export const Head = ({ data }) => {
  return (
    <StandardSEO
      SEOData={{
        ...data.post.seo,
        blogPreviewImage: data.post.mainImage.asset.url,
      }}
    />
  );
};
