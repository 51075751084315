import React from 'react';
import LazyLoad from 'react-lazyload';

import { Box, CircularProgress } from '@mui/material';

import { FanGoTextProcessor } from '../../common/portableText';
import styles from '../../common/styling/styles';
import { MainText } from '../../common/texts';

const BlogBodyFragment = ({ data }) => {
  const { title, body, image } = data;

  return (
    <Box sx={{ pb: 3, pr: { md: 3 } }}>
      {title && (
        <MainText
          textVariant="h2"
          fontColor={styles.deepGreen}
          textAlignment={styles.alignStyleLLLL}
          value={title}
          marginY={styles.allThree}
          fontWeight={500}
        />
      )}

      <Box sx={{ color: styles.grey }}>
        <FanGoTextProcessor text={body} />
      </Box>

      {image !== null && (
        <LazyLoad
          height={400}
          placeholder={
            <div style={{ display: 'flex', height: 400 }}>
              <CircularProgress />
            </div>
          }
        >
          <Box
            aria-label={image?.asset?.altText}
            sx={{
              position: 'relative',
              display: 'flex',
              backgroundImage: `url(${image?.asset?.url})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center center',
              minHeight: 400,
              width: '100%',
              my: 5,
            }}
          ></Box>
        </LazyLoad>
      )}
    </Box>
  );
};

export default BlogBodyFragment;
