import { Box } from "@mui/material";
import React from "react";
import Body from "../../common/body/Body";
import styles from "../../common/styling/styles";
import MainBlogBody from "./MainBlogBody";
import SidePane from "./SidePane";
import BlogMainPicture from "./BlogMainPicture";
import formatDate from "../../common/utils/formatDate";
import SingleBlogPageCategoryDate from "../BlogPageComps/SingleBlogPageCategoryDate";

const BlogBody = ({ data }) => {
  const { publishedAt, category, mainImage } = data;

  const date = formatDate(publishedAt, "DD MMM, YYYY");

  return (
    <Body paddingY={styles.allZero} backgroundColor="white">
      <Box sx={{ pb: 10 }}>
        <BlogMainPicture
          imageUrl={mainImage.asset.url}
          altText={mainImage.altText}
        />

        <SingleBlogPageCategoryDate category={category} date={date} />

        <Box
          sx={{
            display: { xs: "block", md: "flex" },
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <MainBlogBody data={data} />

          <Box
            sx={{
              display: { xs: "none", md: "block" },
              width: "25%",
              height: "100%",
              overflow: "auto",
              position: { md: "sticky" },
              top: "5%",
            }}
          >
            <SidePane data={data} />
          </Box>
        </Box>
      </Box>
    </Body>
  );
};

export default BlogBody;
