import { KeyboardBackspace } from '@mui/icons-material';
import { Box, Link } from '@mui/material';
import React from 'react';
import styles from '../../common/styling/styles';
import { SubText } from '../../common/texts';

const BackButton = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <KeyboardBackspace sx={{ color: styles.blogBlue, mr: 1 }} />

      <Link color="#fff" href="/blog/" underline="none">
        <SubText
          variant="subtitle2"
          fontWeight={700}
          fontColor={styles.blogBlue}
          marginY={styles.allZero}
          textAlignment={styles.alignStyleLLLL}
          value="Go Back to Blog"
        />
      </Link>
    </Box>
  );
};

export default BackButton;
