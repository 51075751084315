import { Box, Divider } from "@mui/material";
import React from "react";
import BackButton from "./BackButton";
import Author from "./Author";
import styles from "../../common/styling/styles";
import SocialMediaShareLinks from "./SocialMediaShareLinks";

const SidePane = ({ data }) => {
  const { title, slug } = data;

  const url = `fangocloud.co.uk/blog/${slug.current}/`;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: styles.lightGrey,
        borderRadius: "16px",
        p: "40px",
        minwidth: { xs: "100%", md: "25%" },
        my: 2,
      }}
    >
      <BackButton />

      <Divider sx={{ my: 5, borderStyle: "dashed" }} />

      <Author author={data.author} />

      <SocialMediaShareLinks title={title} shareUrl={url} />
    </Box>
  );
};

export default SidePane;
