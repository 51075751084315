import { Box } from "@mui/material";
import React from "react";
import { SubText } from "../../common/texts";
import styles from "../../common/styling/styles";

const SingleBlogPageCategoryDate = ({ category, date }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 1,
        justifyItems: "center",
        my: 2,
      }}
    >
      <SubText
        fontColor={styles.blogBlue}
        textAlignment={styles.alignStyleLLLL}
        variant="body2"
        fontWeight={700}
        value={category}
        marginY={styles.allZero}
      />

      <Box
        sx={{
          "::before": {
            content: '"|"',
            width: "20px",
            display: "inline",
            margin: "0 4px",
            color: styles.grey,
          },
        }}
      ></Box>
      <SubText
        fontColor={styles.grey}
        textAlignment={styles.alignStyleLLLL}
        variant="body2"
        value={date}
        marginY={styles.allZero}
      />
    </Box>
  );
};

export default SingleBlogPageCategoryDate;
