import { Box } from '@mui/material';
import React from 'react';
import BlogBodyFragment from './BlogBodyFragment';
import SidePane from './SidePane';

const MainBlogBody = ({ data }) => {
  const { paragraphs, ...sidePaneData } = data;

  return (
    <Box sx={{ width: { xs: '100%', md: '75%' }, mr: { xs: 0, md: 2 } }}>
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <SidePane data={sidePaneData} />
      </Box>

      {paragraphs.map((fragment, index) => (
        <BlogBodyFragment key={index} data={fragment} />
      ))}
    </Box>
  );
};

export default MainBlogBody;
