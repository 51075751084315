import React from 'react';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
} from 'react-share';
import { SubText } from '../../common/texts';
import styles from '../../common/styling/styles';

const SocialMediaShareLinks = ({ title, shareUrl }) => {
  return (
    <>
      <SubText
        variant="subtitle2"
        fontColor="rgb(145, 158, 171)"
        marginY={styles.allOne}
        fontWeight={600}
        value="SHARE:"
        textAlignment={styles.alignStyleLLLL}
      />

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: 10,
          alignItems: 'center',
        }}
      >
        <FacebookShareButton url={shareUrl} quote={title}>
          <FacebookIcon size={30} round />
        </FacebookShareButton>

        <LinkedinShareButton url={shareUrl} title={title}>
          <LinkedinIcon size={30} round />
        </LinkedinShareButton>

        <TwitterShareButton url={shareUrl} title={title}>
          <TwitterIcon size={30} round />
        </TwitterShareButton>
      </div>
    </>
  );
};

export default SocialMediaShareLinks;
