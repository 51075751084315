import React from "react";
import BlogBody from "./BlogBody";
import BlogDetailsPageHead from "./BlogDetailsPageHead";
import BlogsPreview from "../CommonComps/blogsPreview/BlogsPreview";
import Separator from "../../common/styling/Separator";

const BlogDetails = ({ data }) => {
  const { post, sanityBlogPage, allSanityPost } = data;

  return (
    <>
      <BlogDetailsPageHead data={post.title} />

      <BlogBody data={post} />

      <Separator height={100} pb={10} />

      <BlogsPreview
        data={{
          ...sanityBlogPage.blogsPreview,
          blogs: allSanityPost.nodes,
        }}
      />
    </>
  );
};

export default BlogDetails;
